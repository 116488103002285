export const environment = {
  production: true,
  isMobile: false
};

export const appSettings = {
  benchmarkUrl: "https://api.gleac.com/Prod/benchmarkapi/",
  billingUrl: "https://api.gleac.com/Prod/billingapi/",
  dashboardUrl: "https://api.gleac.com/Prod/campaignapi/",
  jobIndexUrl: "https://api.gleac.com/Prod/campaignapi/",
  authUrl: "https://api.gleac.com/Prod/userapi/",
  lessonUrl: "https://api.gleac.com/Prod/lessonapi/",
  mobileUrl: "https://api.gleac.com/Prod/mobileapi/",
  miniBenchmarkUrl: "https://api.gleac.com/Prod/assessmentapi/",
  imageStoreUrl: "https://cdn.gleac.com/",
  mentorsUrl: "https://api.gleac.com/Prod/mentorapi/",
  lessonCampaignImageStoreUrl: "https://img.gleac.com/",
  s3BucketUrl: "https://gleac-lessons.s3.ap-south-1.amazonaws.com",
  googleClientId: "396647122651-henfle896316d6tenruitmf1v3tfgaft.apps.googleusercontent.com",
  facebookCientId: "530792468312397",
  stripeKey: "pk_live_51In3TKIY8DWrcOyyBDafwdenMwoAMoobYa2VzBwwruA3NcXreTUfIs6fUUIE5hD8og8UnsMkC7U4458e594SpvV400tRM3CXne",
  //stripeKey: "pk_live_51HgOCgDPoTf3gZRXwJJbuq5UN9hvsAcjlK2pvgga21FnN6UflVWOHEzLZ1YPVc9E53395RKQVRrDNeBJ5rc4p38X00EkAvaA8U",
  miniBenchmarkKey: "sdfsdfsdv6546sdfs",
  guestUserName: "yourvoice+yoma@gleac.com",
  guestPassword: "rTNh!42Kf3nj",
  gleacWebsite: "https://gleac.com",
  ragApiKey: "FZYOv9Eb8GKk16uxAGmZiXhoqdgLt2Ceo4ZlHvdcvp56f1rKTaCvFqwcyrApsRI7",
};
