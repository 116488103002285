import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommunicationComponent } from './components/communication/communication.component';
import { AddCampaignComponent } from './components/add-campaign/add-campaign.component';
import { AssignUserComponent } from './components/assign-user/assign-user.component';
import { BenchmarkLoginComponent } from './components/benchmark-login/benchmark-login.component';
import { BenchmarkUserReportComponent } from './components/benchmark-user-report/benchmark-user-report.component';
import { CampaignDetailComponent } from './components/campaign-detail/campaign-detail.component';
import { CandidateChallengeMobileComponent } from './components/candidate-challenge-mobile/candidate-challenge-mobile.component';
import { CandidateChallengeComponent } from './components/candidate-challenge/candidate-challenge.component';
import { CandidateDashboardComponent } from './components/candidate-dashboard/candidate-dashboard.component';
import { CandidateLessonMobileComponent } from './components/candidate-lesson-mobile/candidate-lesson-mobile.component';
import { CandidateLessonComponent } from './components/candidate-lesson/candidate-lesson.component';
import { ChallengeListComponent } from './components/challenge-list/challenge-list.component';
import { ChallengeComponent } from './components/challenge/challenge.component';
import { CompanyHomeComponent } from './components/company-home/company-home.component';
import { CompanyComponent } from "./components/company/company.component";
import { EventsComponent } from "./components/Events/events.component";
import { ExpoAnalyticsComponent } from "./components/expo-analytics/expo-analytics.component";
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { GoalsLayoutComponent } from './components/goals-layout/goals-layout.component';
import { GoalsLibraryComponent } from './components/goals-library/goals-library.component';
import { GoalsComponent } from './components/goals/goals.component';
import { GroupInnerComponent } from './components/group-inner/group-inner.component';
import { GroupsLayoutComponent } from './components/groups-layout/groups-layout.component';
import { GroupsComponent } from './components/groups/groups.component';
import { InvalidLessonUrlComponent } from './components/invalid-lesson-url/invalid-lesson-url.component';
import { LessonCampaignComponent } from './components/lesson-campaign/lesson-campaign.component';
import { LessonDetailComponent } from './components/lesson-detail/lesson-detail.component';
import { LessonLibraryComponent } from './components/lesson-library/lesson-library.component';
import { LessonScheduleComponent } from './components/lesson-schedule/lesson-schedule.component';
import { LessonSkillDetailComponent } from './components/lesson-skill-detail/lesson-skill-detail.component';
import { LessonSkillUpsertComponent } from './components/lesson-skill-upsert/lesson-skill-upsert.component';
import { LessonUpsertComponent } from './components/lesson-upsert/lesson-upsert.component';
import { LessonsComponent } from './components/lessons/lessons.component';
import { LoginComponent } from './components/login/login.component';
import { MentorsComponent } from './components/mentors/mentors.component';
import { MiniBenchmarkReportComponent } from './components/mini-benchmark-report/mini-benchmark-report.component';
import { MiniBenchmarkComponent } from './components/mini-benchmark/mini-benchmark.component';
import { PaymentErrorComponent } from './components/payment-error/payment-error.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { ReadinessReportComponent } from './components/readiness-report/readiness-report.component';
import { RequestsComponent } from './components/requests/requests.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RolesComponent } from "./components/roles/roles.component";
import { SkillAddComponent } from './components/skill-add/skill-add.component';
import { TaskSelectComponent } from './components/task-select/task-select.component';
import { UserActivityReportComponent } from './components/user-activity-report/user-activity-report.component';
import { UserReportComponent } from './components/user-report/user-report.component';
import { UserComponent } from './components/user/user.component';
import { UsersComponent } from "./components/users/users.component";
import { VerifyAccountComponent } from './components/verify-account/verify-account.component';
import { VerifyClientAccountComponent } from './components/verify-client-account/verify-client-account.component';
import { VotingPagingComponent } from './components/voting-paging/voting-paging.component';
import { AdminGuardService } from './guards/admin-guard.service';
import { AuthenticationGuard } from './guards/authentication-guard.service';
import { DesktopGuardService } from './guards/desktop-guard.service';
import { MobileGuardService } from './guards/mobile-guard.service';
import { TokenGuardService } from './guards/token-guard.service';
import { BenchmarkLoginLayoutComponent } from './layouts/benchmark-login-layout/benchmark-login-layout.component';
import { CandidateLayoutComponent } from './layouts/candidate-layout/candidate-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { CommunicationLayoutComponent } from './components/communication-layout/communication-layout.component';
import { CommunicationsLibraryComponent } from './components/communications-library/communications-library.component';
import { NewScoringTestComponent } from "./components/new-scoring-test/new-scoring-test.component";
import { NftBundleComponent } from "./components/nft-bundles/nft-bundle.component";
import { NftMentorBookingsComponent } from "./components/nft-mentor-bookings/nft-mentor-bookings.component";
import { MentorBookingsComponent } from "./components/mentor-bookings/mentor-bookings.component";
import { CouponsComponent } from "./components/coupons/coupons.component";
import { StreakReportComponent } from './components/streak-report/streak-report.component';
import { TopLessonTakerReportComponent } from './components/top-lesson-taker-report/top-lesson-taker-report.component';
import { DiscussionReplyComponent } from './components/discussion-reply/discussion-reply.component';
import { LessonRagMetricsComponent } from './components/lesson-rag-metrics/lesson-rag-metrics.component';
import { VoiceOnboardingsComponent } from './components/voice-onboardings/voice-onboardings.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        component: TaskSelectComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'companies',
        component: CompanyHomeComponent,
        canActivate: [AuthenticationGuard],
        children: [
          {
            path: '',
            component: CompanyComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'users',
            component: UserComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'users/:id',
            component: UserComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'groups/:companyId',
            component: GroupsComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'groups/:companyId/:groupId',
            component: GroupInnerComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'campaign/:id',
            component: LessonCampaignComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'campaign/:id/new',
            component: AddCampaignComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'campaign/:id/:campaignId/edit',
            component: AddCampaignComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'campaign/:campaignId/:companyId/assign-user',
            component: AssignUserComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'campaign/:campaignId/:companyId/assign-user/edit',
            component: AssignUserComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'campaign/:companyId/schedule/:campaignId',
            component: LessonScheduleComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'campaign/:companyId/schedule/:campaignId/edit',
            component: LessonScheduleComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'campaign/:id/detail/:campid',
            component: CampaignDetailComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'activity/report/:id',
            component: UserActivityReportComponent,
            canActivate: [AuthenticationGuard],
          }

        ]
      },
      {
        path: 'select',
        component: TaskSelectComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'activity/report',
        component: UserActivityReportComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'LessonReport_View' },
        children: [
          {
            path: '',
            component: UserActivityReportComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: ':id',
            component: UserActivityReportComponent,
            canActivate: [AuthenticationGuard],
          }
        ]
      },
      {
        path: 'readiness/report',
        component: ReadinessReportComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'RedinessReport_View' },
      },
      {
        path: 'benchmark/report',
        component: BenchmarkUserReportComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'user/report',
        component: UserReportComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'mini-benchmark-report',
        component: MiniBenchmarkReportComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'MiniBenchmark_View' },
      },
      {
        path: 'challenge/:categoryId/new',
        component: ChallengeComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'Lesson_View' },
      },
      {
        path: 'mentors',
        component: MentorsComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'Mentor_View' },
      },
      {
        path: 'mentor/nft-bundles',
        component: NftBundleComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'mentor/nft-bookings',
        component: NftMentorBookingsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'mentor/bookings',
        component: MentorBookingsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'mentor/requests',
        component: RequestsComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'Mentor_View' },
      },
      {
        path: 'mentor/challenges',
        component: ChallengeListComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'Mentor_View' },
      },
      {
        path: 'mentor/coupons',
        component: CouponsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'lessons',
        component: LessonsComponent,
        children: [
          {
            path: '',
            component: LessonLibraryComponent,
            canActivate: [AuthenticationGuard],
            data: { permission: 'Lesson_View' },
          },
          {
            path: 'library',
            component: LessonLibraryComponent,
            canActivate: [AuthenticationGuard],
            data: { permission: 'Lesson_View' },
          },
          {
            path: 'scoring-test',
            component: NewScoringTestComponent,
            canActivate: [AuthenticationGuard],
            data: { permission: 'Lesson_View' },
          },
          {
            path: 'new',
            component: LessonSkillUpsertComponent,
            canActivate: [AuthenticationGuard],
            data: { permission: 'Lesson_View' },
          },
          {
            path: ':categoryId/new',
            component: LessonUpsertComponent,
            canActivate: [AuthenticationGuard],
            data: { permission: 'Lesson_View' },
          },
          {
            path: ':categoryId/:levelId/new',
            component: LessonUpsertComponent,
            canActivate: [AuthenticationGuard],
            data: { permission: 'Lesson_View' },
          },
          {
            path: ':categoryId/:lessonId',
            component: LessonDetailComponent,
            canActivate: [AuthenticationGuard],
            data: { permission: 'Lesson_View' },
          },
          {
            path: ':categoryId',
            component: LessonSkillDetailComponent,
            canActivate: [AuthenticationGuard],
            data: { permission: 'Lesson_View' },
          },
        ],
        data: { permission: 'Lesson_View' },
      },
      {
        path: 'goals',
        component: GoalsLayoutComponent,
        data: { permission: 'Goal_View' },
        children: [
          {
            path: '',
            redirectTo: 'goals-view',
            pathMatch: 'full',
            // component : GoalsComponent,
            // canActivate: [AuthenticationGuard, AdminGuardService]
          },
          {
            path: 'goals-view',
            component: GoalsComponent,
            canActivate: [AuthenticationGuard, AdminGuardService]
          },
          {
            path: 'goals-library',
            component: GoalsLibraryComponent,
            canActivate: [AuthenticationGuard, AdminGuardService]
          }
        ]
      },
      {
        path: 'groups',
        component: GroupsLayoutComponent,
        children: [
          {
            path: 'groups',
            component: GroupsComponent,
            canActivate: [AuthenticationGuard]
          },
          {
            path: 'inner/:groupId/:groupName',
            component: GroupInnerComponent,
            canActivate: [AuthenticationGuard]
          }
        ]
      },
      {
        path: 'skill-add',
        component: SkillAddComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'User_View' },
      },
      {
        path: 'events',
        component: EventsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'company',
        component: CompanyComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'Company_View' },
      },
      {
        path: 'expo-analytics',
        component: ExpoAnalyticsComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'Expo_View' },
      },
      {
        path: 'streak-report',
        component: StreakReportComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'Expo_View' },
      },
      {
        path: 'top-lesson-taker-report',
        component: TopLessonTakerReportComponent,
        canActivate: [AuthenticationGuard],
        data: { permission: 'Expo_View' },
      },
      {
        path: 'roles',
        component: RolesComponent,
        canActivate: [AuthenticationGuard, AdminGuardService],
        data: { permission: 'Role_View' },
      },
      {
        path: 'communication',
        component: CommunicationLayoutComponent,
        data: { permission: 'Communication_View' },
        children: [
          {
            path: '',
            redirectTo: 'communication-view',
            pathMatch: 'full',
          },
          {
            path: 'communication-view',
            component: CommunicationComponent,
            canActivate: [AuthenticationGuard, AdminGuardService]
          },
          {
            path: 'communication-library',
            component: CommunicationsLibraryComponent,
            canActivate: [AuthenticationGuard, AdminGuardService]
          },
          {
            path: 'communication-library/:id',
            component: CommunicationsLibraryComponent,
            canActivate: [AuthenticationGuard, AdminGuardService]
          }
        ]
      },
      {
        path: 'communication',
        component: CommunicationComponent,
        canActivate: [AuthenticationGuard, AdminGuardService],
        data: { permission: 'Role_View' },
      },
      {
        path: 'lesson-rag-metrics',
        component: LessonRagMetricsComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'voice-onboardings',
        component: VoiceOnboardingsComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'voting-page/:challengeId',
    component: VotingPagingComponent,
  },
  {
    path: 'challenge/:id',
    component: CandidateChallengeComponent,
    canActivate: [MobileGuardService, TokenGuardService]
  },
  {
    path: '',
    component: CandidateLayoutComponent,
    canActivate: [TokenGuardService],
    children: [
      {
        path: 'lesson/:id',
        component: CandidateLessonComponent,
        canActivate: [MobileGuardService]
        //canActivate: [CandidateGuardService]
      },
      {
        path: 'lesson/t/:title',
        component: CandidateLessonComponent,
        canActivate: [MobileGuardService]
      },
      {
        path: 'candidate/:id',
        component: CandidateDashboardComponent,
        canActivate: [MobileGuardService]
      },
      {
        path: 'candidate/:candidateId/:lessonId',
        component: CandidateLessonComponent,
        canActivate: [MobileGuardService]
      },
      {
        path: 'demo/:demoLessonId',
        component: CandidateLessonComponent,
        canActivate: [MobileGuardService]
      },
      {
        path: 'invite/:lessonId',
        component: CandidateLessonComponent,
        canActivate: [MobileGuardService]
      },
      {
        path: 'invalid',
        component: InvalidLessonUrlComponent
      }
    ]
  },
  {
    path: 'm/lesson/:id',
    component: CandidateLessonMobileComponent,
    canActivate: [DesktopGuardService, TokenGuardService]
  },
  {
    path: 'm/lesson/t/:title',
    component: CandidateLessonMobileComponent,
    canActivate: [DesktopGuardService, TokenGuardService]
  },
  {
    path: 'm/challenge/:id',
    component: CandidateChallengeMobileComponent,
    canActivate: [DesktopGuardService, TokenGuardService]
  },
  {
    path: 'm/invite/:lessonId',
    component: CandidateLessonMobileComponent,
    canActivate: [DesktopGuardService, TokenGuardService]
  },
  {
    path: 'm/candidate/:id',
    component: CandidateLessonMobileComponent,
    canActivate: [DesktopGuardService, TokenGuardService]
  },
  {
    path: 'm/candidate/:candidateId/:lessonId',
    component: CandidateLessonMobileComponent,
    canActivate: [DesktopGuardService, TokenGuardService]
  },
  {
    path: 'm/demo/:demoLessonId',
    component: CandidateLessonMobileComponent,
    canActivate: [DesktopGuardService, TokenGuardService]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'password/forgot',
        component: ForgotPasswordComponent
      },
      {
        path: 'password/reset',
        component: ResetPasswordComponent
      },
      {
        path: 'client/account/verify',
        component: VerifyClientAccountComponent
      },
      {
        path: 'account/verify',
        component: VerifyAccountComponent
      }
    ]
  },
  {
    path: 'benchmark',
    component: BenchmarkLoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: BenchmarkLoginComponent
      },
      {
        path: 'password/forgot',
        component: ForgotPasswordComponent
      },
      {
        path: 'password/reset',
        component: ResetPasswordComponent
      },
      {
        path: 'payment/success',
        component: PaymentSuccessComponent
      },
      {
        path: 'payment/error',
        component: PaymentErrorComponent
      }
    ]
  },
  {
    path: 'mini-benchmark',
    component: MiniBenchmarkComponent,
    canActivate: [TokenGuardService]
  },
  {
    path: 'mini-benchmark/:jobRoleId',
    component: MiniBenchmarkComponent,
    canActivate: [TokenGuardService]
  },
  {
    path: 'discussion-reply',
    component: DiscussionReplyComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
