import { Component, OnInit } from '@angular/core';
import { ConfigsService } from 'src/app/services/configs.service';
import { ConversationService } from 'src/app/services/conversation.service';
import { UsersService } from 'src/app/services/users.service';
import { GleacColumnDef } from '../data-grid-new-dashboard/data-grid-new-dashboard.model';

@Component({
  selector: 'app-lesson-rag-metrics',
  templateUrl: './lesson-rag-metrics.component.html',
  styleUrls: ['./lesson-rag-metrics.component.less']
})
export class LessonRagMetricsComponent implements OnInit {

  lessonRagConversation: any[] = [];
  voiceOnboardingConversation: any[] = [];
  iserrorPopup: boolean = false;
  error_popup = {};
  pageLimit: number = 10;
  pageNumber: number = 1;
  searchString: string = "";
  selectedConversation: any = null;
  showConversationPopup: boolean = false;
  gridData: any;
  selectedType: string = "";
  filter: string = "ALL";

  filterArray: any = [
    {
      label: "Type",
      type: "static_select",
      key: "status",
      options: [
        { name: "Micropractice", value: "micropractice" },
        { name: "LinkedIn", value: "linkedin" },
        { name: "Document", value: "document" },
      ],
    }
  ];

  columnData: GleacColumnDef[] = [
    {
      displayName: "ChatID",
      data: "id",
      isClickable: true,
      actionName: "viewConversation",
      render: (data, row) => {
        return `<span style="color:#0065AD">${row.id || '-'}</span>`;
      },
    },
    {
      displayName: "Type",
      data: "type",
      render: (data, row) => {
        return `<span>${row.type || '-'}</span>`;
      },
    },
    {
      displayName: "Micropractice ID",
      data: "micropracticeId",
      render: (data, row) => {
        return `<span>${row.micropracticeId || '-'}</span>`;
      },
    },
    {
      displayName: "Messages",
      data: "messageCount",
      render: (data, row) => {
        return `<span>${row.conversation ? row.conversation.length : 0}</span>`;
      },
    },
    {
      displayName: "WhatsApp Number",
      data: "phone",
      render: (data, row) => {
        return `<span>${row.phone ? '+' + row.phone : '-'}</span>`;
      },
    }
  ];

  constructor(private conversationService: ConversationService, private configService: ConfigsService, private usersService: UsersService) { }

  ngOnInit() {
    this.usersService.isAI = true;
    this.getlessonRagConversations();
  }

  getlessonRagConversations() {
    this.configService.loading(true);
    this.conversationService.getLessonRagConversation(this.pageNumber, this.pageLimit, this.selectedType).subscribe((result: any) => {
      this.configService.loading(false);
      this.gridData = {
        list: result.conversations,
        totalCount: result.total
      };
      this.lessonRagConversation = result.conversations;
    }, (err) => {
      this.configService.loading(false);
      this.iserrorPopup = true;
      this.error_popup = { display: true, message: err.error.errors[0] };
    });
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getlessonRagConversations();
  }

  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getlessonRagConversations();
  }

  customFilter(params) {
    if (params[0]) {
      const data = params[1].form.value;
      this.selectedType = data.status || '';
    } else {
      this.selectedType = '';
    }
    this.pageNumber = 1;
    this.getlessonRagConversations();
  }

  actionClick(event) {
    if (event.actionName === "viewConversation") {
      this.selectedConversation = event.data;
      this.showConversationPopup = true;
    }
  }

  closeConversationPopup() {
    this.showConversationPopup = false;
    this.selectedConversation = null;
  }

  formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  getFullLinkedInUrl(url: string): string {
    if (!url) return '#';
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `https://${url}`;
  }
}
