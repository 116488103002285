import { Injectable } from "@angular/core";
import { appSettings } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ConversationService {

    constructor(private http: HttpClient) { }

    private getAuthHeaders() {
        return {
            headers: new HttpHeaders({
                Authorization: `Bearer ${appSettings.ragApiKey}`
            })
        };
    }

    getLessonRagConversation(page: number = 1, limit: number = 10, type?: string) {
        const url = `https://growflo.ai/api/admin/getConversations?page=${page}&limit=${limit}${type ? `&type=${type}` : ''}`;
        return this.http.get(url, this.getAuthHeaders());
    }

    getVoiceOnboardingConversation(page: number = 1, limit: number = 10, type?: string) {
        const url = `https://onboarding.gleac.com/api/admin/getConversations?page=${page}&limit=${limit}${type ? `&type=${type}` : ''}`;
        return this.http.get(url, this.getAuthHeaders());
    }
}