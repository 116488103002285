import {BrowserModule} from "@angular/platform-browser";
import {NgModule, APP_INITIALIZER} from "@angular/core";
import {CookieModule} from "ngx-cookie";
import {CalendarModule} from "primeng/calendar";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {LoginComponent} from "./components/login/login.component";
import {UserComponent} from "./components/user/user.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AngularFontAwesomeModule} from "angular-font-awesome";
import {DataGridComponent} from "./components/data-grid/data-grid.component";
import {DataTablesModule} from "angular-datatables";
import {CompaniesComponent} from "./components/companies/companies.component";
import {
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatTooltipModule,
} from "@angular/material";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SafeHtmlPipe} from "./pipes/safe-html.pipe";
import {ExpandableRowDirective} from "./directives/expandable-row.directive";
import {LottieAnimationViewModule} from "ng-lottie";
import {BadgeComponent} from "./components/badge/badge.component";
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS,} from "@angular/common/http";
import {LoginLayoutComponent} from "./layouts/login-layout/login-layout.component";
import {HomeLayoutComponent} from "./layouts/home-layout/home-layout.component";
import {JwtInterceptorService} from "./interceptors/jwt-interceptor.service";
import {LessonsComponent} from "./components/lessons/lessons.component";
import {TaskSelectComponent} from "./components/task-select/task-select.component";
import {CandidateLayoutComponent} from "./layouts/candidate-layout/candidate-layout.component";
import {CandidateLessonComponent} from "./components/candidate-lesson/candidate-lesson.component";
import {ToTimerPipe} from "./pipes/to-timer.pipe";
import {TimerComponent} from "./components/common/timer/timer.component";
import {LessonSectionDirective} from "./directives/lesson-section.directive";
import {LessonSkillUpsertComponent} from "./components/lesson-skill-upsert/lesson-skill-upsert.component";
import {LessonDetailComponent} from "./components/lesson-detail/lesson-detail.component";
import {LessonSkillDetailComponent} from "./components/lesson-skill-detail/lesson-skill-detail.component";
import {LessonUpsertComponent} from "./components/lesson-upsert/lesson-upsert.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {QuillModule} from "ngx-quill";
import {InvitePopupComponent} from "./dialogs/invite-popup/invite-popup.component";
import {InvitePopupWhatsAppComponent} from "./dialogs/invite-popup-whatsapp/invite-popup-whatsapp.component";
import {SimilarityIndexComponent} from "./components/common/similarity-index/similarity-index.component";
import {CandidateLessonMobileComponent} from "./components/candidate-lesson-mobile/candidate-lesson-mobile.component";
import {CandidateDashboardComponent} from "./components/candidate-dashboard/candidate-dashboard.component";
import {LessonFinishedComponent} from "./dialogs/lesson-finished/lesson-finished.component";
import {LessonCustomizeComponent} from "./components/lesson-customize/lesson-customize.component";
import {DeleteConfirmationComponent} from "./dialogs/delete-confirmation/delete-confirmation.component";
import {InvalidLessonUrlComponent} from "./components/invalid-lesson-url/invalid-lesson-url.component";
import {BenchmarkUserReportComponent} from "./components/benchmark-user-report/benchmark-user-report.component";
import {UserActivityReportComponent} from "./components/user-activity-report/user-activity-report.component";
import {AnswerPopupComponent} from "./dialogs/answer-popup/answer-popup.component";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./components/reset-password/reset-password.component";
import {UserReportComponent} from "./components/user-report/user-report.component";
import {LoginPopupComponent} from "./dialogs/login-popup/login-popup.component";
import {ConfigsService} from "./services/configs.service";
import {SharedModule} from "./shared/shared.module";
import {GroupsComponent} from "./components/groups/groups.component";
import {LessonCampaignComponent} from "./components/lesson-campaign/lesson-campaign.component";
import {AddCampaignComponent} from "./components/add-campaign/add-campaign.component";
import {AssignUserComponent} from "./components/assign-user/assign-user.component";
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {AccordionModule} from 'primeng/accordion';
import {CheckboxModule} from 'primeng/checkbox';
import {SidebarModule} from 'primeng/sidebar';
import {LessonScheduleComponent} from './components/lesson-schedule/lesson-schedule.component';
import {SubmenuComponent} from './components/common/submenu/submenu.component';
import {PaginatorModule} from 'primeng/paginator';
import {CampaignDetailComponent} from './components/campaign-detail/campaign-detail.component';
import {TooltipModule} from 'primeng/tooltip';
import {GroupInnerComponent} from './components/group-inner/group-inner.component';
import {CompanyHomeComponent} from './components/company-home/company-home.component';
import {CartComponent} from './components/common/cart/cart.component';
import {SkillAddComponent} from './components/skill-add/skill-add.component';
import {CookiePopupComponent} from './dialogs/cookie-popup/cookie-popup.component';
import {VerifyAccountComponent} from './components/verify-account/verify-account.component';
import {
  DataGirdClientSidePaginationComponent
} from './components/data-gird-client-side-pagination/data-gird-client-side-pagination.component';
import {
  DataGirdServerSidePaginationComponent
} from './components/data-grid-server-side-pagination/data-gird-server-side-pagination.component';
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {BenchmarkLoginLayoutComponent} from './layouts/benchmark-login-layout/benchmark-login-layout.component';
import {BenchmarkLoginComponent} from './components/benchmark-login/benchmark-login.component';
import {BenchmarkPopupComponent} from './dialogs/benchmark-popup/benchmark-popup.component';
import {PaymentSuccessComponent} from './components/payment-success/payment-success.component';
import {VerifyClientAccountComponent} from './components/verify-client-account/verify-client-account.component';
import {NgxEmojiPickerModule} from 'ngx-emoji-picker';
import {PaymentErrorComponent} from './components/payment-error/payment-error.component';
import {SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider} from "angular-6-social-login";
import {appSettings} from 'src/environments/environment';
import {MiniBenchmarkComponent} from "./components/mini-benchmark/mini-benchmark.component";
import {
  MiniBenchmarkLoginPopupComponent
} from './dialogs/mini-benchmark-login-popup/mini-benchmark-login-popup.component';
import {MiniBenchmarkReportComponent} from './components/mini-benchmark-report/mini-benchmark-report.component';
import {HighchartsChartModule} from "highcharts-angular";
import {ReadinessReportComponent} from './components/readiness-report/readiness-report.component';
import {DataGridNewDashboardComponent} from './components/data-grid-new-dashboard/data-grid-new-dashboard.component';
import {LessonLibraryComponent} from './components/lesson-library/lesson-library.component';
import {
  LessonCampaignNewDashboardComponent
} from './components/lesson-campaign-new-dashboard/lesson-campaign-new-dashboard.component';
import {DragAndDropDirective} from './directives/drag-and-drop.directive';
import {ImportLessonComponent} from './components/import-lesson/import-lesson.component';
import {PreviewLessonComponent} from './components/preview-lesson/preview-lesson.component';
import {ChallengeComponent} from './components/challenge/challenge.component';
import {MobilePreviewComponent} from './components/mobile-preview/mobile-preview.component';
import {VotingPagingComponent} from './components/voting-paging/voting-paging.component';
import {UserAnswerCardComponent} from './components/user-answer-card/user-answer-card.component';
import {QuestionCardComponent} from './components/question-card/question-card.component';
import {CandidateChallengeComponent} from './components/candidate-challenge/candidate-challenge.component';
import {
  CandidateChallengeMobileComponent
} from './components/candidate-challenge-mobile/candidate-challenge-mobile.component';
import {MentorsComponent} from './components/mentors/mentors.component';
import {AssignMentorComponent} from './components/assign-mentor/assign-mentor.component';
import {RequestsComponent} from './components/requests/requests.component';
import {ChallengeListComponent} from './components/challenge-list/challenge-list.component';
import {UsersComponent} from "./components/users/users.component";
import {CompanyComponent} from "./components/company/company.component";
import {DatePipe} from '@angular/common';
import {NgbPopoverModule, NgbAlertModule, NgbToastModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ExpoAnalyticsComponent} from "./components/expo-analytics/expo-analytics.component";
import {ToastsContainer} from "./components/toast/toast.component";
import {GoalsComponent} from "./components/goals/goals.component";
import {GoalsLibraryComponent} from "./components/goals-library/goals-library.component";
import {GoalsLayoutComponent} from "./components/goals-layout/goals-layout.component";
import {RolesComponent} from "./components/roles/roles.component";
import {AddRoleComponent} from "./components/add-role/add-role.component";
import {TreeviewModule} from 'ngx-treeview';
import {AssignRoleComponent} from "./components/assign-role/assign-role.component";
import {appInitializer} from "./app.initializer";
import {UsersService} from "./services/users.service";
import {AssignUserToCompanyComponent} from "./components/assign-user-to-company/assign-user-to-company.component";
import {GoalsPopupComponent} from './dialogs/goal-popup/goals-popup.component';
import {GroupsLayoutComponent} from './components/groups-layout/groups-layout.component';
import {AddGroupComponent} from './components/add-group/add-group.component';
import {AssignUserToGroupComponent} from './components/assign-user-to-group/assign-user-to-group.component';
import {DebounceClickDirective} from './directives/debounce-click-directive';
import {AssignUserToCampaignComponent} from "./components/assign-user-to-campaign/assign-user-to-campaign.component";
import {EventsComponent} from "./components/Events/events.component";
import {NgModelChangeDebouncedDirective} from "./directives/ng-model-change-debounced";
import {CommunicationComponent} from './components/communication/communication.component';
import {CommunicationLayoutComponent} from './components/communication-layout/communication-layout.component';
import {CommunicationsPopupComponent} from "./dialogs/communication-popup/communications-popup.component";
import {EmailEditorModule} from "angular-email-editor";
import {CommunicationsLibraryComponent} from "./components/communications-library/communications-library.component";
import {FileUploadModule} from 'primeng/fileupload';
import {LessonInviteStatusComponent} from "./dialogs/lesson-invite-status/lesson-invite-status.component";
import {SafePipeModule} from "safe-pipe";
import {NewScoringTestComponent} from "./components/new-scoring-test/new-scoring-test.component";
import {NgxAudioPlayerModule} from "./lib/ngx-audio-player.module";
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {GestureConfig} from '@angular/material';
import 'hammerjs';
import {NftBundleComponent} from "./components/nft-bundles/nft-bundle.component";
import {AddNftBundleComponent} from "./components/add-nft-bundle/add-nft-bundle.component";
import {AngularPageVisibilityModule} from "angular-page-visibility";
import {NftMentorBookingsComponent} from "./components/nft-mentor-bookings/nft-mentor-bookings.component";
import {MentorBookingsComponent} from "./components/mentor-bookings/mentor-bookings.component";
import {CouponsComponent} from "./components/coupons/coupons.component";
import { StreakReportComponent } from "./components/streak-report/streak-report.component";
import { TopLessonTakerReportComponent } from "./components/top-lesson-taker-report/top-lesson-taker-report.component";
import { DemoPopupComponent } from "./dialogs/demo-popup/demo-popup.component";
import { WhatsappLessonSubscriptionPopupComponent } from "./dialogs/whatsapp-lesson-subscription-popup/whatsapp-lesson-subscription-popup.component";
import { DiscussionReplyComponent } from './components/discussion-reply/discussion-reply.component';
import { MenuModule } from 'primeng/menu';
import { LessonRagMetricsComponent } from './components/lesson-rag-metrics/lesson-rag-metrics.component';
import { VoiceOnboardingsComponent } from './components/voice-onboardings/voice-onboardings.component';

let config = new AuthServiceConfig(
  [
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(appSettings.facebookCientId)
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(appSettings.googleClientId)
    }
  ]);

export function getAuthServiceConfigs() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    DataGridComponent,
    CompaniesComponent,
    SafeHtmlPipe,
    ExpandableRowDirective,
    BadgeComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    LessonsComponent,
    TaskSelectComponent,
    CandidateLayoutComponent,
    CandidateLessonComponent,
    ToTimerPipe,
    TimerComponent,
    LessonSectionDirective,
    LessonSkillUpsertComponent,
    LessonDetailComponent,
    LessonSkillDetailComponent,
    LessonUpsertComponent,
    InvitePopupComponent,
    InvitePopupWhatsAppComponent,
    LessonInviteStatusComponent,
    SimilarityIndexComponent,
    CandidateLessonMobileComponent,
    CandidateChallengeMobileComponent,
    CandidateDashboardComponent,
    LessonFinishedComponent,
    LessonCustomizeComponent,
    DeleteConfirmationComponent,
    InvalidLessonUrlComponent,
    BenchmarkUserReportComponent,
    UserActivityReportComponent,
    AnswerPopupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserReportComponent,
    LoginPopupComponent,
    DemoPopupComponent,
    WhatsappLessonSubscriptionPopupComponent,
    GroupsComponent,
    LessonCampaignComponent,
    AddCampaignComponent,
    AssignUserComponent,
    LessonScheduleComponent,
    SubmenuComponent,
    CampaignDetailComponent,
    GroupInnerComponent,
    CompanyHomeComponent,
    CartComponent,
    SkillAddComponent,
    CookiePopupComponent,
    VerifyAccountComponent,
    DataGirdClientSidePaginationComponent,
    DataGirdServerSidePaginationComponent,
    BenchmarkLoginLayoutComponent,
    BenchmarkLoginComponent,
    BenchmarkPopupComponent,
    PaymentSuccessComponent,
    VerifyClientAccountComponent,
    PaymentErrorComponent,
    MiniBenchmarkComponent,
    MiniBenchmarkLoginPopupComponent,
    MiniBenchmarkReportComponent,
    ReadinessReportComponent,
    DataGridNewDashboardComponent,
    LessonLibraryComponent,
    LessonCampaignNewDashboardComponent,
    DragAndDropDirective,
    ImportLessonComponent,
    PreviewLessonComponent,
    ChallengeComponent,
    MobilePreviewComponent,
    VotingPagingComponent,
    UserAnswerCardComponent,
    QuestionCardComponent,
    CandidateChallengeComponent,
    MentorsComponent,
    AssignMentorComponent,
    RequestsComponent,
    ChallengeListComponent,
    UsersComponent,
    CompanyComponent,
    ExpoAnalyticsComponent,
    StreakReportComponent,
    TopLessonTakerReportComponent,
    ToastsContainer,
    GoalsComponent,
    GoalsLibraryComponent,
    GoalsLayoutComponent,
    RolesComponent,
    AddRoleComponent,
    AssignRoleComponent,
    AssignUserToCompanyComponent,
    GoalsPopupComponent,
    GroupsLayoutComponent,
    AddGroupComponent,
    AssignUserToGroupComponent,
    DebounceClickDirective,
    AssignUserToCampaignComponent,
    EventsComponent,
    CommunicationComponent,
    CommunicationLayoutComponent,
    CommunicationsPopupComponent,
    CommunicationsLibraryComponent,
    NewScoringTestComponent,
    NftBundleComponent,
    AddNftBundleComponent,
    NftMentorBookingsComponent,
    MentorBookingsComponent,
    CouponsComponent,
    DiscussionReplyComponent,
    LessonRagMetricsComponent,
    VoiceOnboardingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    DataTablesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatAutocompleteModule,
    DragDropModule,
    MatDialogModule,
    MatDatepickerModule,
    CalendarModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    NgMultiSelectDropDownModule.forRoot(),
    QuillModule.forRoot(),
    LottieAnimationViewModule.forRoot(),
    AccordionModule,
    CheckboxModule,
    SharedModule,
    SidebarModule,
    PaginatorModule,
    TooltipModule,
    CookieModule.forRoot(),
    ToastModule,
    NgxEmojiPickerModule.forRoot(),
    SocialLoginModule,
    HighchartsChartModule,
    NgbPopoverModule,
    NgbAlertModule,
    NgbToastModule,
    TreeviewModule.forRoot(),
    NgbModule,
    EmailEditorModule,
    FileUploadModule,
    SafePipeModule,
    NgxAudioPlayerModule,
    AngularPageVisibilityModule,
    MatTooltipModule,
    MenuModule,
  ],
  entryComponents: [
    BadgeComponent,
    InvitePopupComponent,
    InvitePopupWhatsAppComponent,
    LessonInviteStatusComponent,
    LessonFinishedComponent,
    DeleteConfirmationComponent,
    AnswerPopupComponent,
    LoginPopupComponent,
    DemoPopupComponent,
    WhatsappLessonSubscriptionPopupComponent,
    BenchmarkPopupComponent,
    MiniBenchmarkLoginPopupComponent,
    GoalsPopupComponent,
    CommunicationsPopupComponent,
  ],
  providers: [
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    {provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [UsersService]},
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
      deps: [ConfigsService],
    },
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
