import { Component, OnInit } from '@angular/core';
import { ConfigsService } from 'src/app/services/configs.service';
import { ConversationService } from 'src/app/services/conversation.service';
import { UsersService } from 'src/app/services/users.service';
import { GleacColumnDef } from '../data-grid-new-dashboard/data-grid-new-dashboard.model';

@Component({
  selector: 'app-voice-onboardings',
  templateUrl: './voice-onboardings.component.html',
  styleUrls: ['./voice-onboardings.component.less']
})
export class VoiceOnboardingsComponent implements OnInit {
  voiceOnboardingConversations: any[] = [];
  iserrorPopup: boolean = false;
  error_popup = {};
  pageLimit: number = 10;
  pageNumber: number = 1;
  searchString: string = "";
  selectedConversation: any = null;
  showConversationPopup: boolean = false;
  gridData: any;
  selectedStatus: string = "";

  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "status",
      options: [
        { name: "Completed", value: "completed" },
        { name: "Not Completed", value: "notCompleted" },
      ],
    }
  ];

  columnData: GleacColumnDef[] = [
    {
      displayName: "ID",
      data: "id",
      isClickable: true,
      actionName: "viewConversation",
      render: (data, row) => {
        return `<span style="color:#0065AD">${row.id || '-'}</span>`;
      },
    },
    {
      displayName: "Name",
      data: "name",
      render: (data, row) => {
        return `<span>${row.firstName} ${row.lastName || '-'}</span>`;
      },
    },
    {
      displayName: "Email",
      data: "email",
      render: (data, row) => {
        return `<span>${row.email || '-'}</span>`;
      },
    },
    {
      displayName: "Status",
      data: "status",
      render: (data, row) => {
        return `<span>${row.isCompleted ? 'Completed' : 'Not Completed'}</span>`;
      },
    },
    {
      displayName: "Messages",
      data: "messageCount",
      render: (data, row) => {
        return `<span>${row.messages ? row.messages.length : 0}</span>`;
      },
    }
  ];

  constructor(private conversationService: ConversationService, private configService: ConfigsService, private usersService: UsersService) { }

  ngOnInit() {
    this.usersService.isAI = true;
    this.getVoiceOnboardingConversations();
  }

  getVoiceOnboardingConversations() {
    this.configService.loading(true);
    this.conversationService.getVoiceOnboardingConversation(this.pageNumber, this.pageLimit, this.selectedStatus).subscribe((result: any) => {
      this.configService.loading(false);
      this.gridData = {
        list: result.conversations,
        totalCount: result.total
      };
      this.voiceOnboardingConversations = result.conversations;
    }, (err) => {
      this.configService.loading(false);
      this.iserrorPopup = true;
      this.error_popup = { display: true, message: err.error.errors[0] };
    });
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getVoiceOnboardingConversations();
  }

  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getVoiceOnboardingConversations();
  }

  customFilter(params) {
    if (params[0]) {
      const data = params[1].form.value;
      this.selectedStatus = data.status || '';
    } else {
      this.selectedStatus = '';
    }
    this.pageNumber = 1;
    this.getVoiceOnboardingConversations();
  }

  actionClick(event) {
    if (event.actionName === "viewConversation") {
      this.selectedConversation = event.data;
      this.showConversationPopup = true;
    }
  }

  closeConversationPopup() {
    this.showConversationPopup = false;
    this.selectedConversation = null;
  }

  getFullLinkedInUrl(url: string): string {
    if (!url) return '#';
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `https://${url}`;
  }
}
